import * as S from './FormField.styles';
import { Controller, useFormState } from 'react-hook-form';
import { useTheme } from 'styled-components';

const FormField = ({ name, label, variant = 'outlined', type, rule }) => {
	const formState = useFormState();
	const theme = useTheme();
	return (
		<Controller
			name={name}
			render={({ field: { onChange, onBlur, value, ref } }) => (
				<>
					<S.Label
						sx={{
							color: theme.formPalette.labelColor,
							fontSize: '0.8rem',
							margin: '0.5rem 0 0',
						}}
					>
						{label}
					</S.Label>
					<S.TextInput
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						ref={ref}
						variant={variant}
						placeholder={label}
						sx={{
							'& legend': { display: 'none' },
							'& fieldset': { top: 0 },
						}}
						inputProps={{ sx: { color: theme.formPalette.inputPlaceholderColor } }}
						style={{ margin: '0.4rem 0 0.2rem 0' }}
						fullWidth
						error={Boolean(formState.errors[name])}
						helpertext={formState.errors[name]?.message}
						type={type}
					/>
					{Boolean(formState.errors[name]) && (
						<S.Label
							sx={{
								color: theme.formPalette.errorColor,
								fontSize: '0.7rem',
								margin: '0 0 0.4rem',
							}}
						>
							{formState.errors[name]?.message}
						</S.Label>
					)}
				</>
			)}
			rules={rule}
		/>
	);
};

export default FormField;
