import {
	SET_AXIOS_INSTANCE,
	SET_SCREENSHOT_REF,
	SET_BANNERS,
	SET_DEVICE,
	SET_HEADER,
	SET_INITIAL_LEAGUES,
	SET_SAVED_ODDS,
	SET_SAVED_PREDICTIONS,
	SET_SELECTED_LEAGUE_TAB,
	SET_SELECTED_MARKET_TAB,
	SET_SELECTED_PARLAYS,
	SET_USER,
	TOGGLE_EDIT_MODE,
	TOGGLE_MODIFY_MODE,
	TOGGLE_HAS_ROUND_STARTED,
	TOGGLE_SIDE_NAV,
	UPDATE_HEADER,
	UPDATE_SAVED_PREDICTIONS,
	TOGGLE_MUTATE_ODDS_FLAG,
	TOGGLE_ASKED_FOR_NICKNAME,
	INIT_STATE,
	LOGOUT,
	SET_COUNTER,
	SET_SHOW_DISCLAIMER,
	SET_SHOW_NICKNAME,
} from './globalActions';
import { produce } from 'immer';
import isNullOrUndefined from '../../utils/is-null-or-undefined';
import axios, { AxiosInstance } from 'axios';

type TLeague = {
	title: string;
	to: string;
};

type TLeagues = Array<TLeague>;

type THeaderConfig = {
	height?: string | number;
	title?: string;
	page?: string;
	description?: string;
	clientLogo?: File;
	shareUrl?: string;
	banner?: File;
	leagues?: TLeagues;
	color?: string;
	tabsBgColor?: string;
	descColor?: string;
	hideHeader?: boolean;
	hideBurger?: boolean;
	hideHowToPlay?: boolean;
	[key: string]: any;
};

type TBanners = {
	results?: Array<object>;
	predictions?: Array<object>;
};

type TPrediction = {
	matchId: Number;
	home_score: Number;
	away_score: Number;
};

type TSavedPredictions = {
	[key: string]: Array<TPrediction>;
};

type TParlay = {
	oddId: string | number;
	market: string;
	outcomeId: string;
	isSelected: boolean;
};

type TSavedOdds = {
	[key: string]: Array<TParlay>;
};

type TCounter = {
	desktopBg?: File;
	mobileBg?: File;
};

type TInitialState = {
	sideNavToggle: boolean;
	axiosInstance: AxiosInstance | null;
	device: boolean | null | string;
	editMode: boolean;
	modifyMode: boolean;
	hasRoundStarted: boolean;
	savedPredictions: TSavedPredictions;
	selectedParlays: Array<TParlay>;
	savedOdds: TSavedOdds;
	user: object | null | undefined;
	banners: TBanners;
	shareUrl?: string;
	header?: THeaderConfig;
	initialLeagues?: TLeagues;
	selectedLeagueTab: number | string;
	selectedMarketTab: number | string;
	screenshotRef: null | HTMLDivElement;
	mutateOddsFlag: boolean;
	askedForNickname: boolean;
	counter?: TCounter;
	showDisclaimerModal?: boolean | null;
	showNicknameModal?: boolean;
};

const initialState: TInitialState = {
	sideNavToggle: false,
	axiosInstance: null,
	askedForNickname: true,
	device: null,
	editMode: false,
	modifyMode: false,
	hasRoundStarted: false,
	savedPredictions: {},
	savedOdds: {},
	selectedParlays: [],
	user: null,
	banners: { results: [], predictions: [] },
	header: {},
	initialLeagues: [],
	selectedLeagueTab: 0,
	selectedMarketTab: 0,
	screenshotRef: null,
	mutateOddsFlag: true,
	counter: {},
	showDisclaimerModal: null,
	showNicknameModal: false,
};

const globalReducer = produce((draft: TInitialState = initialState, action) => {
	if (action.type === SET_SHOW_NICKNAME) {
		console.log('Dispatched: ', action.type);
		console.log('Payload:', action.payload);
	}
	switch (action.type) {
		case INIT_STATE:
			draft = initialState;
			return draft;
		case LOGOUT:
			draft.askedForNickname = initialState.askedForNickname;
			draft.editMode = initialState.editMode;
			draft.modifyMode = initialState.modifyMode;
			draft.savedPredictions = initialState.savedPredictions;
			draft.savedOdds = initialState.savedOdds;
			draft.selectedParlays = initialState.selectedParlays;
			draft.user = initialState.user;
			return draft;
		case SET_DEVICE:
			draft.device = action.payload;
			return draft;
		case SET_SCREENSHOT_REF:
			draft.screenshotRef = action.payload;
			return draft;
		case TOGGLE_SIDE_NAV:
			draft.sideNavToggle =
				action.payload !== null ? action.payload : !draft.sideNavToggle;
			return draft;
		case TOGGLE_MUTATE_ODDS_FLAG:
			draft.mutateOddsFlag =
				action.payload !== null ? action.payload : !draft.mutateOddsFlag;
			return draft;
		case SET_AXIOS_INSTANCE:
			draft.axiosInstance = action.payload;
			return draft;
		case TOGGLE_ASKED_FOR_NICKNAME:
			draft.askedForNickname =
				action.payload !== null ? action.payload : !draft.askedForNickname;
			return draft;
		case TOGGLE_EDIT_MODE:
			draft.editMode = action.payload !== null ? action.payload : !draft.editMode;
			return draft;
		case TOGGLE_MODIFY_MODE:
			draft.modifyMode =
				action.payload !== null ? action.payload : !draft.modifyMode;
			return draft;
		case TOGGLE_HAS_ROUND_STARTED:
			draft.hasRoundStarted =
				action.payload !== null ? action.payload : !draft.hasRoundStarted;
			return draft;
		case SET_USER:
			draft.user = action.payload;
			return draft;
		case SET_SAVED_PREDICTIONS:
			const { leagueName: setPredictionsLeagueName, savedPredictions } =
				action.payload;
			if (draft.savedPredictions[setPredictionsLeagueName]?.length) {
				draft.savedPredictions[setPredictionsLeagueName] = [...savedPredictions];
			}
			draft.savedPredictions = {
				...draft.savedPredictions,
				[setPredictionsLeagueName]: [...savedPredictions],
			};
			return draft;

		case UPDATE_SAVED_PREDICTIONS:
			const { leagueName, prediction } = action.payload;
			const index = draft.savedPredictions[leagueName]?.findIndex(
				(savedPred) => savedPred.matchId === prediction.matchId
			);
			if (isNullOrUndefined(index) || index < 0) {
				draft.savedPredictions[leagueName]?.length
					? draft.savedPredictions[leagueName].push(prediction)
					: (draft.savedPredictions[leagueName] = [prediction]);
				return draft;
			}
			if (!isNullOrUndefined(prediction.home_score))
				draft.savedPredictions[leagueName][index].home_score =
					prediction.home_score;
			if (!isNullOrUndefined(prediction.away_score))
				draft.savedPredictions[leagueName][index].away_score =
					prediction.away_score;
			return draft;
		case SET_BANNERS:
			draft.banners = action.payload;
			return draft;
		case SET_COUNTER:
			draft.counter = action.payload;
			return draft;
		case SET_HEADER:
			draft.header = {
				clientLogo: draft.header?.clientLogo,
				shareUrl: draft.header?.shareUrl,
				height: draft.header?.height,
				...action.payload,
			};
			return draft;
		case UPDATE_HEADER:
			if (draft.header) {
				const { payload } = action;
				for (const key in payload) {
					const element = payload[key];
					draft.header[key] = element;
				}
			}
			return draft;
		case SET_INITIAL_LEAGUES:
			draft.initialLeagues = action.payload;
			return draft;
		case SET_SELECTED_LEAGUE_TAB:
			draft.selectedLeagueTab = action.payload;
			return draft;
		case SET_SELECTED_MARKET_TAB:
			draft.selectedMarketTab = action.payload;
			return draft;
		case SET_SELECTED_PARLAYS:
			draft.selectedParlays = action.payload;
			return draft;
		case SET_SAVED_ODDS:
			const { league, parlays } = action.payload;
			draft.savedOdds[league] = parlays;
			return draft;
		case SET_SHOW_DISCLAIMER:
			draft.showDisclaimerModal = action.payload;
			return draft;
		case SET_SHOW_NICKNAME:
			draft.showNicknameModal = action.payload;
			return draft;
		default:
			return draft;
	}
});

export default globalReducer;
