import styled from 'styled-components';
import FormField from '../../components/FormField';
import FormLabel from '@mui/material/FormLabel';

export const Gender = styled.div`
	display: flex;
	flex-direction: column;
`;
export const SelectRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const FormSelectContainer = styled.div`
	width: 45%;
	.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
		border-radius: 1.85rem !important;
	}
`;

export const GenderLabel = styled(FormLabel)`
	color: ${({ theme }) => theme.mainTypography} !important;
`;

export const GenderRadioGroup = styled(FormField)`
	display: flex !important;
`;

export const TncText = styled.h4`
	color: ${({ theme }) => theme.formPalette.formTextColor};
	font-size: 0.7rem;
	margin-top: 0.7rem;
	text-align: center;
`;
export const TncLink = styled.a`
	color: ${({ theme }) => theme.formPalette.formLink};
	font-size: 0.7rem;
	text-decoration: none;
	&:hover {
		color: ${({ theme }) => theme.formPalette.formLink};
		text-decoration: none;
		cursor: pointer;
	}
`;
