import * as S from './AuthModalHeader.styles';
import { BackArrowSmall } from 'shared/assets/images/Arrows';
import StyledSvgIcon from '../../../StyledSvgIcon/StyledSvgIcon';
import { useTheme } from 'styled-components';
import { CloseIcon } from 'shared/assets/images/menu';

const AuthModalHeader = ({
	titleText,
	HeaderButtonText,
	HeaderButtonOnPress,
	activeStep,
	handleBack,
	onClose,
	children,
}) => {
	const theme = useTheme();

	return (
		<S.HeaderContainer>
			{activeStep > 0 && (
				<S.HeaderButton onClick={handleBack}>
					<StyledSvgIcon
						defaultColor={theme.modalPalette.backButtonFillColor}
						SvgIcon={BackArrowSmall}
					/>
				</S.HeaderButton>
			)}
			<S.Title>{titleText}</S.Title>

			{HeaderButtonText && (
				<S.HeaderButton HeaderButtonOnPress={HeaderButtonOnPress}>
					<S.HeaderButtonText>{HeaderButtonText}</S.HeaderButtonText>
				</S.HeaderButton>
			)}
			<S.HeaderButton onClick={onClose}>
				<StyledSvgIcon
					defaultColor={theme.modalPalette.backButtonFillColor}
					SvgIcon={CloseIcon}
				/>
			</S.HeaderButton>
			{children}
		</S.HeaderContainer>
	);
};

export default AuthModalHeader;
