import { Backdrop, Fade, Modal } from '@mui/material';
import StepperRegisteation from '../../../Form/forms/StepperRegisteation';
import ReactPortal from '../../../ReactPortal/ReactPortal';
import * as S from './RegisterModal.styles';
import Form from '../../../Form';
import { useState } from 'react';
import AuthModalHeader from '../AuthModalHeader';
import AuthModalFooter from '../AuthModalFooter';
import { useTheme } from 'styled-components';
const registerationInputs = {
	email: '',
	userName: '',
	password: '',
	gender: '',
	firstName: '',
	lastName: '',
};

const RegisterModal = ({ open, onClose, onLeave, texts }) => {
	// states
	const [errorMessage, setErrorMessage] = useState(null);
	const [activeStep, setActiveStep] = useState(1);

	const theme = useTheme();

	const firstStepValidationHandler = (formData) => {
		return !formData?.email || !formData?.userName || !formData?.password;

		//!some validation here
	};

	const onFooterBtnClick = () => {
		return;
	};

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		console.log('aaa');
		setActiveStep(activeStep - 1);
	};

	const handleSubmit = (formData) => {
		console.log(formData);
		if (activeStep === 0) {
			let goToNexStep = firstStepValidationHandler();
			if (goToNexStep) {
				handleNext();
			} else {
				alert('aaa');
			}
		}
	};

	const handleError = (err) => {
		console.log(err);
	};

	return (
		<ReactPortal wrapperId={'register-modal'}>
			<Modal
				disablePortal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					overflowY: 'scroll',
				}}
				open={open}
				onClose={onClose}
			>
				<Fade in={open}>
					<S.Paper>
						<AuthModalHeader
							HeaderButtonText={'Have a coupon code to activate?'}
							titleText={'SIGN UP NOW'}
							handleBack={handleBack}
							activeStep={activeStep}
							onClose={onClose}
						/>
						<Form
							formDefaultValues={registerationInputs}
							handleSubmit={handleSubmit}
							handleError={handleError}
						>
							<StepperRegisteation
								activeStep={activeStep}
								handleBack={handleBack}
								// handleNext={handleNext}
							/>
						</Form>
						<AuthModalFooter
							marginTop={'2rem'}
							bgColor={theme.modalPalette.authFooterBgColor}
						>
							<S.FooterText>
								{'By proceeding you confirm you are 18 years of age or older.'}
							</S.FooterText>
							<S.FooterButton onClick={onFooterBtnClick}>
								{/* ADD a proper svg  */}
								{/* <StyledSvgIcon
									defaultColor={theme.primaryWhite}
									SvgIcon={BackArrowSmall}
								/> */}
								<S.FooterButtonText>{'Live Chat'}</S.FooterButtonText>
							</S.FooterButton>
						</AuthModalFooter>
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

export default RegisterModal;
