import { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import * as S from './FooterNav.styles';
import { useTheme } from 'styled-components';
import { useWindowResize } from 'shared/hooks';
import PagesEnum from 'shared/Enum/pages';
import CountDown from '../CountDown/CountDown';

export const FooterNav = forwardRef(
	({ ItemsComponent, showFooterCounter, counterFontFamily }, ref) => {
		// hooks
		const theme = useTheme();
		const queryClient = useQueryClient();
		const { selectedLeagueTab } = useSelector(({ global }) => global);
		const { width, isMobile } = useWindowResize();
		const { pathname } = useLocation();

		// states
		const [showCountDown, setShowCountDown] = useState(true);

		//constans
		const jwt = localStorage.getItem('jwt-token');
		const leagues = queryClient.getQueryData(['leagues',jwt]);
			const relevantCountdownData =
			leagues && leagues[Object.keys(leagues)[selectedLeagueTab]]?.countDownData;
		const isCounterDisplay =
			showFooterCounter && pathname.includes(PagesEnum.predictions);

		// jsx
		return (
			<S.Container data-html2canvas-ignore className="nav-container" ref={ref}>
				{isCounterDisplay &&
					showCountDown &&
					relevantCountdownData &&
					width <= 500 && (
						<div className="w-100 d-flex justify-content-center">
							<CountDown
								counterFontFamily={counterFontFamily}
								enableClose
								counterPosition={isMobile ? 'bottom' : ''}
								countDownData={relevantCountdownData}
								onClose={() => setShowCountDown(false)}
							/>
						</div>
					)}
				<S.Ul className="navbar-nav">
					<ItemsComponent
						defaultColor={theme.footerPalette.defaultColor}
						activeColor={theme.footerPalette.activeColor}
					/>
				</S.Ul>
			</S.Container>
		);
	}
);

export default FooterNav;
