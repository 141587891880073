import * as S from './Button.styles';
import { useTheme } from 'styled-components';
export const Button = ({
	className,
	title = 'Button',
	icon,
	onClick = null,
	textColor,
	bgColor,
	width,
	height,
	style,
	toggle = false,
	setToggle,
	disabled = false,
	SvgIcon,
	children,
	inline,
	disableBorder,
	...otherProps
}) => {
	const theme = useTheme();
	const onClickHandler = () => {
		setToggle && setToggle((prev) => !prev);
		if (onClick) {
			onClick();
		}
	};

	return inline ? (
		<S.Button
			disableBorder={disableBorder}
			data-html2canvas-ignore
			width={width}
			height={height}
			bgColor={bgColor || theme.appSectionsBg}
			textColor={textColor || theme.secondary}
			onClick={onClickHandler}
			className={`${className ? className : ''}`}
			style={style}
			{...otherProps}
		>
			<span>{children}</span>
		</S.Button>
	) : (
		<S.Button
			disableBorder={disableBorder}
			data-html2canvas-ignore
			disabled={disabled}
			width={width}
			height={height}
			bgColor={disabled ? theme.btnDisabled : bgColor || theme.secondary}
			textColor={
				disabled
					? theme.submitButton?.disabled?.textColor || theme.mainTypographyDisabled
					: textColor || theme.black
			}
			toggle={toggle}
			icon={Boolean(icon)}
			SvgIcon={Boolean(SvgIcon)}
			onClick={onClickHandler}
			className={`${className ? className : ''}`}
			style={style}
			{...otherProps}
		>
			{SvgIcon && (
				// <div>
				<SvgIcon
					fill={!toggle ? theme.editBtn.textColor : theme.editBtn.disabledTextColor}
				/>
				// </div>
			)}
			{icon && <img src={icon} alt="icon" />}
			<span>{children ? children : title}</span>
		</S.Button>
	);
};

export default Button;
