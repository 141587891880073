import styled from 'styled-components';

export const FooterContainer = styled.div`
	width: 100%;
	padding: 2rem 1rem;
	margin-top: 0.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 0;
	margin-top: ${({ margintop }) => margintop};
	background-color: ${({ bgcolor }) => bgcolor};
`;
