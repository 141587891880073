import { useDispatch, useSelector } from 'react-redux';
import {
	setSelectedLeagueTab,
	toggleSideNavHandler,
} from 'shared/store/global/globalActions';
import { HamburgerIcon } from 'shared/assets/images/menu';
import Banner from '../../Banner/Banner';
import HeaderTabs from '../HeaderTabs';
import * as S from './MobileHeader.styles';
import { useTheme } from 'styled-components';

export const MobileHeader = ({
	pageName,
	banner,
	leagues,
	toggleSideNav,
	clientLogo,
	MobileSideNavComponent,
	color,
	hideBurger = false,
	tabsBgColor = '#fff',
}) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { selectedLeagueTab } = useSelector(({ global }) => global);

	const setSelectedTab = (value) => {
		dispatch(setSelectedLeagueTab(value));
	};

	const sideMenuHandler = (e) => {
		e.stopPropagation();
		dispatch(toggleSideNavHandler());
	};

	return (
		<>
			<S.MobileHeader>
				<S.Logos>
					{/* <S.StyledLink href="https://google.com" target="_top">
						<BackArrow fill={theme.white} />
					</S.StyledLink> */}
					{!hideBurger ? (
						<S.BurgerWrapper>
							<HamburgerIcon fill={theme.white} onClick={sideMenuHandler} />
						</S.BurgerWrapper>
					) : (
						<div />
					)}
					<img src={clientLogo} alt="logo" width={88} />
				</S.Logos>
				{banner && <Banner banner={banner} />}
				{!!leagues?.length && (
					<HeaderTabs
						selectedTab={selectedLeagueTab}
						setSelectedTab={setSelectedTab}
						routes={leagues}
						variant={leagues.length === 1 ? 'fullWidth' : 'scrollable'}
					/>
				)}
			</S.MobileHeader>
			{/* <div>Round</div> */}
		</>
	);
};

export default MobileHeader;
