import axios, { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from 'react-query';

// const fetchServerLogin = (axiosInstance: AxiosInstance,
//   serverKey: string,
//   originServer: string) => {
//   return axiosInstance.post(`/auth/server-login`, {
//     serverKey,
//     name: originServer,
//   })
// }
// const fetchAuthenticateUser = (axiosInstance: AxiosInstance,
//   UserId: string,
//   serverToken: string,
//   originServer: string) => {
//   return axiosInstance.post(
//     `/server-api/authenticate-user`,
//     {
//       UserId,
//       originServer,
//     },
//     {
//       headers: {
//         'jwt-token': serverToken,
//       },
//     }
//   );
// }

// const fetchUser = (axiosInstance: AxiosInstance) => {
//   return axiosInstance.get('/auth/profile')
// }

export const authUserHandler = async (
	axiosInstance: AxiosInstance,
	serverKey: string,
	UserId: string,
	originServer: string
) => {
	try {
		const {
			data: { jwtToken: serverToken },
		} = await axiosInstance.post(`/auth/server-login`, {
			serverKey,
			name: originServer,
		});
		// if (serverToken) {
		const {
			data: { jwtToken },
		} = await axiosInstance.post(
			`/server-api/authenticate-user`,
			{
				UserId,
				originServer,
			},
			{
				headers: {
					'jwt-token': serverToken,
				},
			}
		);
		if (jwtToken) {
			localStorage.setItem('jwt-token', jwtToken);
			axiosInstance.defaults.headers.common['jwt-token'] = jwtToken;
			const { data } = await axiosInstance.get('/auth/profile');
			return data;
		}
		// }
	} catch (err) {
		console.log('authUserHandler', err);
		throw err;
	}
};

const useAuth = (
	axiosInstance: AxiosInstance,
	serverKey: string,
	UserId: string,
	originServer: string
) => {
	const queryClient = useQueryClient();
	const jwt = localStorage.getItem('jwt-token');
	// console.log({ enabled: Boolean(UserId && axiosInstance) });
	// if (!UserId) {
	// 		localStorage.removeItem('jwt-token');
	// 		delete axiosInstance?.defaults?.headers?.common?.['jwt-token'];
	// 	}
	return useQuery(
		['user', UserId],
		() => authUserHandler(axiosInstance, serverKey, UserId, originServer),
		{
			retry: 1,
			enabled: Boolean(UserId && axiosInstance),
			onError: (err) => console.error('[useAuth]: fetch failed ', err),
			// onSuccess: () => queryClient.refetchQueries({ active: true }),
			onSuccess: (data) => {
				queryClient.refetchQueries(['leagues', jwt]);
			},
		}
	);
};

export default useAuth;
