import { NavLink, useLocation } from 'react-router-dom';
import * as S from './NavItem.styles';
import { QuestionMarkIcon } from 'shared/assets/images/menu';
import StyledSvgIcon from '../../StyledSvgIcon/StyledSvgIcon';
import { useState } from 'react';
import { Share } from '../../Share/Share';

export const NavItem = ({
	to,
	onClick,
	SvgIcon,
	text = '',
	defaultColor = '#fff',
	activeColor = '#fff',
	stroke,
	style,
	activeBg,
	children,
	src,
	disabled,
}) => {
	if (children) {
		return (
			<li style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
				<S.Image disabled={disabled} onClick={disabled ? null : onClick}>
					{children}
				</S.Image>
				<S.Text color={defaultColor}>{text.toLowerCase()}</S.Text>
			</li>
		);
	}

	const Icon = (isActive) =>
		!src ? (
			<img src={src} alt="icon" />
		) : (
			<QuestionMarkIcon fill={isActive ? activeColor : defaultColor} />
		);

	return (
		<li className="nav-item w-100">
			<NavLink
				onClick={(e) => onClick(e, to, disabled)}
				to={to}
				className="nav-link"
				style={{
					...style,
					padding: 'unset',
					cursor: disabled ? 'not-allowed' : 'pointer',
				}}
			>
				{({ isActive }) => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							backgroundColor: isActive && activeBg ? activeBg : '',
							cursor: disabled ? 'not-allowed' : 'pointer',
							background: isActive && activeBg ? activeBg : '',
							height: '6rem',
						}}
					>
						<S.Image disabled={disabled} onClick={onClick}>
							{SvgIcon ? (
								<StyledSvgIcon
									defaultColor={defaultColor}
									isActive={isActive}
									SvgIcon={SvgIcon}
									activeColor={activeColor}
									stroke={stroke}
								/>
							) : (
								<Icon isActive={isActive} />
							)}
						</S.Image>
						<S.Text color={isActive ? activeColor : defaultColor}>
							{text.toLowerCase()}
						</S.Text>
					</div>
				)}
			</NavLink>
		</li>
	);
};

export default NavItem;
