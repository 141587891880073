import moment from 'moment';
import { forwardRef, useEffect, useState } from 'react';
import * as S from './CountDown.styles';
import './CountDown.css';
import { Xicon } from 'shared/assets/images/CountDown';
import Timer from './Timer';
import useWindowResize from 'shared/hooks/useWindowResize';
import { AppSpinner } from '../AppSpinner/AppSpinner';
import isNullOrUndefined from 'shared/utils/is-null-or-undefined';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'moment/locale/es';

const CountDown = forwardRef(
	(
		{ onClose, countDownData, counterPosition, enableClose, counterFontFamily },
		ref
	) => {
		// hooks
		const { width, isMobile } = useWindowResize();
		const { pathname } = useLocation();
		const { counter } = useSelector(({ global }) => global);

		// states
		const [timerTimeLeft, setTimerTimeLeft] = useState(null);

		// constants
		const isFixedToBottom = counterPosition ? counterPosition === 'bottom' : null;
		const {
			roundName: nextRoundName,
			roundTime: nextRoundTime,
			entriesUntilOrFrom,
		} = countDownData;

		const langState = useSelector(({ lang }) => lang);
		const dateFormat =
			langState.language === 'es'
				? 'ddd D MMMM [a las] HH:mm'
				: 'ddd D MMMM [at] HH:mm';
		let nextRoundSchedule = moment(nextRoundTime).format(dateFormat);
		// moment.updateLocale('es', {
		// 	weekdaysShort: 'Dom_Lun_Mar_Mié_Jue_Vie_Sáb'.split('_'),
		// 	months : 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
		// });

		let isNextRound = moment().isBefore(moment(nextRoundTime));

		// functions
		const calcTimeLeft = () => {
			let timeLeft = {};
			const countDownTime = new Date(nextRoundTime).getTime();
			const currentTime = new Date().getTime();
			const difference = countDownTime - currentTime;

			if (difference > 0) {
				timeLeft = {
					days:
						Math.floor(difference / (1000 * 60 * 60 * 24)) < 10
							? '0' + Math.floor(difference / (1000 * 60 * 60 * 24))
							: Math.floor(difference / (1000 * 60 * 60 * 24)),

					hours:
						Math.floor((difference / (1000 * 60 * 60)) % 24) < 10
							? '0' + Math.floor((difference / (1000 * 60 * 60)) % 24)
							: Math.floor((difference / (1000 * 60 * 60)) % 24),
					minutes:
						Math.floor((difference / 1000 / 60) % 60) < 10
							? '0' + Math.floor((difference / 1000 / 60) % 60)
							: Math.floor((difference / 1000 / 60) % 60),
					seconds:
						Math.floor((difference / 1000) % 60) < 10
							? '0' + Math.floor((difference / 1000) % 60)
							: Math.floor((difference / 1000) % 60),
				};
				return timeLeft;
			} else
				return (timeLeft = {
					days: '0',
					hours: '0',
					minutes: '0',
					seconds: '0',
				});
		};

		const isAtBottom = width <= 500 && isFixedToBottom;
		// life cycle
		useEffect(() => {
			const timer = setTimeout(() => {
				setTimerTimeLeft(calcTimeLeft());
			}, 1000);
			return () => clearTimeout(timer);
		});

		useEffect(() => {
			return () => {
				if (pathname) {
					setTimerTimeLeft(null);
				}
			};
		}, [pathname]);

		// jsx
		if (isNullOrUndefined(timerTimeLeft) && !isFixedToBottom) {
			return <AppSpinner />;
		} else if (isNullOrUndefined(timerTimeLeft)) {
			return null;
		}

		return (
			<S.CountDownContainer
				ref={ref}
				data-html2canvas-ignore
				atBottom={isAtBottom}
				$isMobile={isMobile}
				entriesUntilOrFrom={entriesUntilOrFrom}
				fontFamily={counterFontFamily}
				counter={counter}
			>
				{/**  // Todo: check the icon styling */}
				{enableClose && (
					<S.Xicon onClick={onClose}>
						<Xicon />
					</S.Xicon>
				)}
				<div>
					<S.Title $isMobile={isMobile}>
						{langState.language === 'es'
							? isNextRound
								? `${nextRoundName + ' Empezará'}`
								: `Esperando los datos de la próxima ronda`
							: isNextRound
							? `${
									nextRoundName.includes('Round')
										? nextRoundName
										: 'Round ' + nextRoundName
							  } will start`
							: `Waiting for next round data`}
					</S.Title>
					<S.Description atBottom={isAtBottom}>
						{isNextRound ? `${nextRoundSchedule}` : ''}
					</S.Description>
				</div>
				<S.ClockContainer>
					{timerTimeLeft &&
						Object.keys(timerTimeLeft).map((clockItem, index) => (
							<Timer
								key={clockItem}
								timerTimeLeft={timerTimeLeft}
								clockItem={clockItem}
								index={index}
								isMobile={isMobile}
								atBottom={isAtBottom}
								fontFamily={counterFontFamily}
							/>
						))}
				</S.ClockContainer>
			</S.CountDownContainer>
		);
	}
);

export default CountDown;
