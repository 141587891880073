import * as S from './Form.styles';
import { useForm, FormProvider } from 'react-hook-form';

const Form = ({ children, formDefaultValues, handleSubmit, handleError }) => {
	// hooks
	const form = useForm({ defaultValues: formDefaultValues });

	// functions
	const onSubmit = async (formData) => {
		handleSubmit(formData);
	};

	const onError = (error) => {
		handleError(error);
		// console.log('Error', error);
	};

	// jsx
	return (
		<FormProvider {...form}>
			<S.Form onSubmit={form.handleSubmit(onSubmit, onError)}>{children}</S.Form>
		</FormProvider>
	);
};

export default Form;
