import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as S from './Match.styles';
import Team from '../Team';
import {
	hasMatchEndedYet,
	hasMatchStartedYetByCode,
	matchStatusEnum,
} from 'shared/utils/match-status';
import BetController from '../BetController';
import moment from 'moment';
import 'moment/locale/es';
import ScoreBox from '../ScoreBox';
import Time from '../Time';
import { VS } from 'shared/assets/images/Decoration';
import MatchIndicator from '../MatchIndicator';
import RoundsEnum from 'shared/Enum/RoundsEnum';
import isNullOrUndefined from 'shared/utils/is-null-or-undefined';
import { useDispatch, useSelector } from 'react-redux';
import { updateSavedPredictionsHandler } from 'shared/store/global/globalActions';
import Postponed from '../Postponed/Postponed';

moment.updateLocale('es', {
	weekdaysShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
});

export const Match = ({
	match,
	roundStatus,
	userPredictions,
	leagueName,
	isMobile,
	disableOpacity,
}) => {
	// hooks
	const dispatch = useDispatch();
	const { savedPredictions, editMode } = useSelector(({ global }) => global);
	const langState = useSelector(({ lang }) => lang);

	// states
	const [userSubmission, setUserSubmission] = useState(null);
	const [currPrediction, setCurrPrediction] = useState(null);

	// constants
	const postponedText = langState?.components?.match?.postponed;
	const matchIndicatorTexts = langState?.components?.match?.matchIndicator;
	const { id: matchId, match_start, status_code, matchScore } = match;
	// const homeTeamShirt = match?.homeTeam?.shirt_img;
	// const homeTeamFlag = match?.homeTeam?.flag_img;
	const homeTeamImage = match?.homeTeam?.flag_img || match?.homeTeam?.shirt_img;
	const awayTeamImage = match?.awayTeam?.flag_img || match?.awayTeam?.shirt_img;
	// const awayTeamShirt = match?.awayTeam?.shirt_img;
	// const awayTeamFlag = match?.awayTeam?.flag_img;
	const homeTeamName = match?.homeTeam?.displayName;
	const awayTeamName = match?.awayTeam?.displayName;
	const hasMatchEnded = Boolean(matchScore);
	const hasMatchStarted = moment().isAfter(moment(match_start));
	const isPostponed = +status_code === +matchStatusEnum.Postponed;

	// functions
	const setHomeGoals = (goals) => {
		if (!isNullOrUndefined(goals)) {
			dispatch(
				updateSavedPredictionsHandler(leagueName, { matchId, home_score: goals })
			);
		}
	};

	const setAwayGoals = (goals) => {
		if (!isNullOrUndefined(goals)) {
			dispatch(
				updateSavedPredictionsHandler(leagueName, { matchId, away_score: goals })
			);
		}
	};

	// life cycle
	useEffect(() => {
		const relevantUserPrediction = userPredictions?.find(
			(prediction) => prediction.matchId === matchId
		);
		if (relevantUserPrediction) {
			setUserSubmission(relevantUserPrediction);
		}
	}, [matchId, userPredictions]);

	useLayoutEffect(() => {
		const prediction = savedPredictions[leagueName]?.find(
			(pred) => pred.matchId === matchId
		);
		if (prediction) {
			setCurrPrediction(prediction);
		}
	}, [leagueName, matchId, savedPredictions]);

	return (
		<S.MatchContainer xs={11} md={6}>
			{isPostponed && <Postponed text={postponedText} />}
			<S.Date disableOpacity={disableOpacity}>
				<span>{moment(match_start).format('ddd, D MMMM YYYY')}</span>
				{/* <span>{moment(match_start).format('ddd, D MMM YYYY')}</span> */}
				{/* <span>{moment(match_start).format('ddd, Do MMM YYYY')}</span> */}
			</S.Date>
			<S.Match
				isMobile={isMobile}
				postponed={isPostponed ? isPostponed : undefined}
			>
				{hasMatchEnded &&
					!!userSubmission?.successPoints &&
					userSubmission?.successPoints !== 0 && (
						<MatchIndicator
							texts={matchIndicatorTexts}
							points={userSubmission?.successPoints}
						/>
					)}
				<Team
					image={homeTeamImage}
					// TeamShirt={homeTeamShirt}
					// Flag={homeTeamFlag}
					TeamName={homeTeamName}
				/>

				{hasMatchStarted && matchScore ? (
					<ScoreBox
						match={match}
						userSubmission={userSubmission}
						hasMatchEnded={hasMatchEnded}
					/>
				) : (
					<S.BetControllerContainer>
						<BetController
							disabled={
								roundStatus === RoundsEnum.lastRound ||
								roundStatus !== RoundsEnum.futureRound ||
								(!isNullOrUndefined(userSubmission?.home_score) && !editMode)
							}
							matchId={matchId}
							goals={currPrediction?.home_score ?? userSubmission?.home_score}
							setGoals={setHomeGoals}
							postponed={isPostponed}
						/>

						<S.VsContainer>
							<Time match_hour={match_start} />
							<S.VsTextWrapper>
								{!isPostponed && (
									<>
										<S.VsDecoration src={VS} />
										<S.VsText>VS</S.VsText>
									</>
								)}
							</S.VsTextWrapper>
						</S.VsContainer>

						<BetController
							disabled={
								roundStatus === RoundsEnum.lastRound ||
								roundStatus !== RoundsEnum.futureRound ||
								(!isNullOrUndefined(userSubmission?.away_score) && !editMode)
							}
							matchId={matchId}
							goals={currPrediction?.away_score ?? userSubmission?.away_score}
							setGoals={setAwayGoals}
							postponed={isPostponed}
						/>
					</S.BetControllerContainer>
				)}
				<Team
					image={awayTeamImage}
					// TeamShirt={awayTeamShirt}
					// Flag={awayTeamFlag}
					TeamName={awayTeamName}
				/>
			</S.Match>
		</S.MatchContainer>
	);
};
export default React.memo(Match);
