import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MobileHeader = styled.div`
  background: ${({theme})=> theme.mobileHeader.backgroundColor};
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
  /* padding-top: 1.5rem; */
`;

export const Logos = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;
`;

export const Hamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLink = styled.a`
  position: absolute;
  left: 10px;
  color: ${({theme})=> theme.white};
  font-size: 2em;
  text-decoration: none;
`;

export const BurgerWrapper = styled.div`
	cursor: pointer;
	position: absolute;
	left: 1.25rem;
`;

export const MvpLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
