import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const RoundSummaryWrapper = styled.div`
	width: inherit;
	max-width: inherit;
	padding-bottom: 20px;
`;

export const Title = styled.div`
	font-size: 0.9rem;
	font-weight: 500;
	width: 100%;
	text-align: center;
	padding: 1rem 0;
	font-family: 'Prompt';
	font-style: normal;
	line-height: 21px;
	color: ${({ theme }) => theme?.roundSummary?.titleColor};
`;

export const RoundSummaryContainer = styled(Container)`
	/* max-width: inherit;
	width: inherit; */
	padding: 1rem 0.8rem;
	background: ${({ theme }) => theme?.roundSummary?.backgroundColor};
	box-shadow: 0px 4px 5px rgba(118, 118, 118, 0.1);
	border-radius: 10px;
`;

export const RoundSummaryHead = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	font-weight: 500;
	font-size: 0.8rem;
	border-bottom: ${({ theme }) =>
		`${theme.roundSummary.border.borderWidth} solid ${theme.roundSummary.border.borderColor}`};
	padding-bottom: 1rem;
`;

export const RoundSummaryCell = styled.div`
	flex: 1;
	font-family: 'Prompt';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme?.roundSummary?.textColor};
`;

export const RoundSummaryRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	font-weight: 500;
	font-size: 0.8rem;
	&:not(:last-child) {
		border-bottom: ${({ theme }) =>
			`${theme.roundSummary.border.borderWidth} solid ${theme.roundSummary.border.borderColor}`};
		padding-bottom: 1rem;
	}
	&:not(:first-child) {
		padding-top: 1rem;
	}
	&:last-child {
		font-weight: 700;
	}
`;
