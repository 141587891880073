import { ButtonBase } from '@mui/material';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
	width: 92%;
	margin-top: 0.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: ${({ height }) => height};
`;
export const HeaderButton = styled(ButtonBase)``;

export const Title = styled.h4`
	color: ${({ theme }) => theme.white};
	font-size: 1rem;
	margin: 0;
`;

export const HeaderButtonText = styled.h4`
	color: ${({ theme }) => theme.formPalette.headerButtonTextColor};
	font-size: 0.6rem;
	margin: 0 1rem 0 auto;
`;
