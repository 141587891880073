import * as S from './AuthModalFooter.styles';

const AuthModalFooter = ({ children, marginTop, bgColor }) => {

	return (
		<S.FooterContainer margintop={marginTop} bgcolor={bgColor}>
			{children}
		</S.FooterContainer>
	);
};

export default AuthModalFooter;
