import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { updateHeader } from 'shared/store/global/globalActions';
import * as S from './ResultDetails.styles';
import { BackArrow } from 'shared/assets/images/Arrows';
import HowToPlayLink from '../HowToPlayLink';
import RoundIndicator from '../RoundIndicator';
import Matches from '../Matches';
import useWindowResize from 'shared/hooks/useWindowResize';
import { useTheme } from 'styled-components';
import RoundSummary from './RoundSummary';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';

export const ResultDetails = ({ howToPlayColor }) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { league, roundId } = useParams();
	const { initialLeagues } = useSelector(({ global }) => global);
	const theme = useTheme();
	const navigate = useNavigate();

	const langState = useSelector(({ lang }) => lang);
	const resultsLang = langState?.pages?.results;

	const [relevantCampaignRound, setRelevantCampaignRound] = useState(null);

	const {
		state,
		state: { round, submission },
	} = useLocation();

	const jwt = localStorage.getItem('jwt-token');
	const leagues = queryClient.getQueryData(['leagues', jwt]);
	const relevantResult = !league
		? leagues[initialLeagues[0].to]
		: leagues[league];
	const { relevantRound } = relevantResult;

	const { isMobile } = useWindowResize();

	const checkedPredictions =
		relevantCampaignRound?.submission?.matchPredictions?.reduce(
			(acc, curr) => acc + (curr.status !== 'pending' ? 1 : 0),
			0
		);

	useEffect(() => {
		const keyOfRound = relevantResult?.past_round?.rounds?.findIndex(
			(round) => round.round.id === +roundId
		);
		if (keyOfRound > -1) {
			setRelevantCampaignRound(relevantResult?.past_round?.rounds?.[keyOfRound]);
		} else if (+roundId === relevantRound?.round?.id) {
			setRelevantCampaignRound(relevantRound);
		}
	}, [relevantResult?.past_round, relevantRound, roundId]);

	useEffect(() => {
		if (relevantRound) {
			dispatch(updateHeader({ hideHeader: true }));
		}
	}, [dispatch, relevantRound]);

	useEffect(() => {
		console.log({ relevantCampaignRound });
	}, [relevantCampaignRound]);
	

	return (
		<S.Container>
			<S.ResultsDetailsHeader $isMobile={isMobile}>
				<S.StyledLink $isMobile={isMobile} to={`/results/${league}`}>
					<BackArrow fill={theme.resultsDetailsHeader.backArrow} />
				</S.StyledLink>
				<div>
					<div className="text-center">
						<S.RoundName $isMobile={isMobile}>{round.name}</S.RoundName>
						<S.RoundDate $isMobile={isMobile}>
							{moment(round.roundStart).format('ddd, MMMM D')}
						</S.RoundDate>
					</div>
				</div>
				{!isMobile && (
					<S.HowToPlayWrapper>
						<HowToPlayLink howToPlayColor={howToPlayColor} />
					</S.HowToPlayWrapper>
				)}
			</S.ResultsDetailsHeader>
			{submission && (
				<S.Indicator>
					<RoundIndicator
						submissionResults={submission.matchPredictions}
						matches={round.matches}
					/>
				</S.Indicator>
			)}
			<Matches
				relevantRound={state}
				userPredictions={submission.matchPredictions}
				hideEditBtn
			/>
			{checkedPredictions ? (
				<RoundSummary submission={relevantCampaignRound?.submission} />
			) : null}
			<S.LeaderBoardBtnContainer>
				<Button
					inline
					width="8rem"
					height="2.9rem"
					style={{
						border: `${theme.leaderBoardButton.border.borderColor} solid ${theme.leaderBoardButton.border.borderWidth}`,
						borerRadius: `${theme.leaderBoardButton.border.borderRadius}`,
						padding: '0.8em 0em',
						fontSize: '1em',
						textAlign: 'center',
						color: `${theme.leaderBoardButton.textColor}`,
						backgroundColor: `${theme.leaderBoardButton.backgroundColor}`,
					}}
					onClick={() => {
						navigate(`/leaderboard/${league}`);
					}}
				>
					{resultsLang.sections.summary.leaderboardBtn}
				</Button>
			</S.LeaderBoardBtnContainer>
		</S.Container>
	);
};

export default ResultDetails;
