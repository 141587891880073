import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import PagesEnum from 'shared/Enum/pages';
import { setHeader } from 'shared/store/global/globalActions';
import * as S from './Results.styles';
import BannerSizeEnum from 'shared/Enum/banners-size';
import Banner from '../Banner';
import CountDown from '../CountDown/CountDown';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../Button';
import { Col } from 'react-bootstrap';
import RoundResults from './RoundResults';
import { useTheme } from 'styled-components';
import { useWindowResize } from 'shared/hooks';
import { AppSpinner } from '../AppSpinner/AppSpinner';

export const Results = ({counterFontFamily}) => {
	const dispatch = useDispatch();
	const { league } = useParams();
	const queryClient = useQueryClient();
	const theme = useTheme();
	const navigate = useNavigate();
	const { isMobile, isXsmall, isSmall, isTablet } = useWindowResize();
	const { device, initialLeagues } = useSelector(({ global }) => global);
	const langState = useSelector(({ lang }) => lang);

	const resultsLang = langState?.pages?.results;

	const [banner, setBanner] = useState(null);
	const [bannerRedirectURL, setBannerRedirectURL] = useState(null);
	const [totalSuccessPoints, setTotalSuccessPoints] = useState(null);

	const banners = queryClient
		.getQueryData('banners')
		?.banners.filter((banner) => banner?.page === PagesEnum.results);
		const jwt = localStorage.getItem('jwt-token');
		const leagues = queryClient.getQueryData(['leagues',jwt]);

	const relevantResults = !league
		? leagues[initialLeagues[0].to]
		: leagues[league];

	// Calculating the total success points
	const getTotalSuccessPoints = useCallback(() => {
		let past_rounds = [];
		if (relevantResults?.past_round && relevantResults?.past_round.rounds) {
			past_rounds = relevantResults.past_round.rounds;
		}
		return relevantResults?.current_round?.submission || past_rounds?.length
			? (
					(relevantResults?.current_round?.submission && [
						relevantResults?.current_round,
					]) ||
					[]
			  )
					.concat(...(past_rounds || []))
					?.map((r) => r?.submission?.successPoints || 0)
					?.reduce((prev, curr) => (prev || 0) + (curr || 0), 0) || ''
			: null;
	}, [relevantResults?.current_round, relevantResults?.past_round]);

	useEffect(() => {
		setTotalSuccessPoints(getTotalSuccessPoints());
	}, [getTotalSuccessPoints]);

	// useEffect(() => {
	// 	if (device) {
	// 		if (device === 'ios') {
	// 			setBannerRedirectURL(
	// 				'https://www.codere.mx/deportes/promociones/Apuesta-500-en-vivo-y-si-fallas-una-freebet-de-300?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_iOS_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere'
	// 			);
	// 		} else {
	// 			setBannerRedirectURL(
	// 				'https://www.codere.mx/deportes/promociones/Apuesta-500-en-vivo-y-si-fallas-una-freebet-de-300?clientType=sportsbook&deliveryPlatform=Native&advertiser=MVP_Android_MX&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere'
	// 			);
	// 		}
	// 	} else {
	// 		setBannerRedirectURL(
	// 			'https://www.codere.mx/deportes/promociones/Apuesta-500-en-vivo-y-si-fallas-una-freebet-de-300?clientType=sportsbook&mvp=1&back_url=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere&login_action=https%3A%2F%2Fwww.codere.mx%2Fmvp-de-codere'
	// 		);
	// 	}
	// }, [device]);

	// useEffect(() => {
	// 	if (banners) {
	// 		if (isXsmall) {
	// 			setBanner(banners.find((item) => item.size === BannerSizeEnum.xs).img_url);
	// 		}
	// 		if (isSmall) {
	// 			setBanner(banners.find((item) => item.size === BannerSizeEnum.s).img_url);
	// 		}
	// 		if (isTablet) {
	// 			setBanner(banners.find((item) => item.size === BannerSizeEnum.m).img_url);
	// 		}
	// 	}
	// }, [banners, isSmall, isTablet, isXsmall]);

	// Init
	useEffect(() => {
		dispatch(
			setHeader({
				title: resultsLang?.title,
				page: PagesEnum.results,
				description: '',
				leagues: initialLeagues,
				tabsBgColor: '#fff',
			})
		);
	}, [dispatch, initialLeagues, resultsLang?.title]);

	useLayoutEffect(() => {
		if (!league) {
			navigate(`${initialLeagues[0].to}`);
		}
	}, [initialLeagues, league, navigate]);

	if (!relevantResults) {
		return <AppSpinner />;
	}

	return (
		<S.Results fluid $isMobile={isMobile}>
			<S.StyledRow $isMobile={isMobile}>
				{banner && (
					<S.ResultItem $cancelMargins={isMobile}>
						<Banner banner={banner} redirectURL={bannerRedirectURL} />
					</S.ResultItem>
				)}
			</S.StyledRow>
			{/* <S.StyledRow $isMobile={isMobile}>
				<S.StyledHr>{resultsLang?.sections.nextRound.title}</S.StyledHr>
				<S.ResultItem>
					<CountDown
						enableClose={false}
						counterPosition="top"
						countDownData={relevantResults?.countDownData}
						counterFontFamily={counterFontFamily}
					/>
				</S.ResultItem>
			</S.StyledRow> */}
			{totalSuccessPoints && (
				<S.StyledRow $isMobile={isMobile}>
					<S.StyledHr>{resultsLang?.sections.summary.title}</S.StyledHr>
					<S.ResultItem bgColor={theme.resultsPalette.summarySection.backgroundColor}>
						<S.Summary $isMobile={isMobile}>
							<Col md={3}>
								<span>{resultsLang?.sections.summary.yourTotalScore}</span>
							</Col>
							<Col md={6}>
								<span>
									{totalSuccessPoints}&nbsp;
									<span style={{ color: theme.thirdlyTypography }}>Points</span>
								</span>
							</Col>
							<Col md={3}>
								<Button
									inline
									width="8rem"
									height="2.9rem"
									style={{
										border: `${theme.leaderBoardButton.border.borderColor} solid ${theme.leaderBoardButton.border.borderWidth}`,
										borerRadius: `${theme.leaderBoardButton.border.borderRadius}`,
										padding: '0.8em 0em',
										fontSize: '1em',
										textAlign: 'center',
										color: `${theme.leaderBoardButton.textColor}`,
										backgroundColor: `${theme.leaderBoardButton.backgroundColor}`
									}}
									onClick={() => {
										navigate(`/leaderboard/${league}`);
									}}
								>
									{resultsLang.sections.summary.leaderboardBtn}
								</Button>
							</Col>
						</S.Summary>
					</S.ResultItem>
				</S.StyledRow>
			)}
			{relevantResults?.current_round?.submission && (
				<S.StyledRow $isMobile={isMobile}>
					<S.StyledHr>{resultsLang.sections.currentRound.title}</S.StyledHr>
					<S.ResultItem style={{ flexDirection: 'column' }}>
						<S.StyledLink
							to={`${league}/${relevantResults.current_round.round.id}`}
							key={relevantResults.current_round.round.id}
							state={{
								round: relevantResults.current_round.round,
								submission: relevantResults.current_round.submission,
							}}
						>
							<RoundResults
								totalPointsText={resultsLang?.sections.previouseRounds.totalPoints}
								round={relevantResults.current_round.round}
								submission={relevantResults.current_round.submission}
								isLast={true}
							/>
						</S.StyledLink>
					</S.ResultItem>
				</S.StyledRow>
			)}
			{relevantResults?.past_round?.rounds && (
				<S.StyledRow $isMobile={isMobile}>
					<S.StyledHr>{resultsLang?.sections?.previouseRounds?.title}</S.StyledHr>
					<S.ResultItem style={{ flexDirection: 'column' }}>
						{relevantResults.past_round.rounds.map(({ round, submission }, index) => (
							<S.StyledLink
								to={`${league}/${round.id}`}
								key={round.id}
								state={{ round, submission }}
							>
								<RoundResults
									round={round}
									totalPointsText={resultsLang?.sections.previouseRounds.totalPoints}
									submission={submission}
									isLast={index === relevantResults.past_round.rounds.length - 1}
								/>
							</S.StyledLink>
						))}
					</S.ResultItem>
				</S.StyledRow>
			)}
		</S.Results>
	);
};

export default Results;
