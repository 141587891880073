import { AxiosInstance } from 'axios';
import moment, { Moment } from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import RoundsEnum from '../Enum/RoundsEnum';

type TCountDownData = {
	entriesUntilOrFrom: string | null;
	roundTime: Moment | Date | null;
	roundName: string | null;
};

export const fetchLeaguesAndRounds = async (
	axiosInstance: AxiosInstance,
	clientId: number
) => {
	try {
		const { data } = await axiosInstance.get(
			`/api/get-leagues-and-rounds?clientId=${clientId}`
		);
		return data;
	} catch (err) {
		console.log(err);
		return err;
	}
};

type IUser = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	idInClient: string;
	nickname: string;
};

const useLeagues = (axiosInstance: AxiosInstance, clientId: number) => {
	const jwt = localStorage.getItem('jwt-token');
	return useQuery(
		['leagues', jwt],
		() => fetchLeaguesAndRounds(axiosInstance, clientId),
		{
			enabled: Boolean(axiosInstance),
			onError: (err) => console.error('[useLeagues]: fetch failed ', err),
			onSuccess: (data) => {
				// if (!user?.['id']) {
				// console.log({ userCheck: user?.['id'] });

				// }

				for (const league in data) {
					const countDownData: TCountDownData = {
						entriesUntilOrFrom: null,
						roundTime: null,
						roundName: null,
					};
					// Adding status key to each kind of round
					const leagueObj = data[league];
					for (const key in leagueObj) {
						if (leagueObj[key]) {
							if (leagueObj[key].length) {
								leagueObj[key] = {
									rounds: leagueObj[key],
									key,
								};
							}
							leagueObj[key] = {
								...leagueObj[key],
								key,
							};
						}
					}
					// find the relevant round
					leagueObj.relevantRound =
						leagueObj[RoundsEnum.currentRound] ||
						leagueObj[RoundsEnum.futureRound] ||
						leagueObj[RoundsEnum.lastRound];
					// console.log({
					// 	current: leagueObj[RoundsEnum.currentRound],
					// 	future: leagueObj[RoundsEnum.futureRound],
					// 	last: leagueObj.lastRound,
					// });

					// Adding countdown data obj
					if (leagueObj.relevantRound?.key === RoundsEnum.futureRound) {
						countDownData.entriesUntilOrFrom = 'until';
						countDownData.roundName = leagueObj.relevantRound.round?.name;
						countDownData.roundTime = moment(
							leagueObj.relevantRound.round?.roundStart
						).toDate();
					} else {
						countDownData.entriesUntilOrFrom = 'from';
						countDownData.roundName = leagueObj.relevantRound?.round?.nextRoundName;
						countDownData.roundTime = moment(
							leagueObj.relevantRound?.round?.roundEnd
						).toDate();
					}
					leagueObj.countDownData = countDownData;
					data[league] = leagueObj;
				}
				// }
				return data;
			},
		}
	);
};

export default useLeagues;
