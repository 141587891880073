import * as S from './app.styles';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import {
	Header,
	CampaignLeague,
	SideNav,
	Results,
	FooterNav,
	ResultDetails,
	Leaderboard,
	Parlays,
	AppSpinner,
	TnCWrapper,
	HowToPlayWrapper,
	ErrorPage,
	WorldCupBracket,
	DisclaimerModal,
} from '@hook-apps-client/ui';
import VersusLogo from '../assets/images/logos/versus_logo.svg';
import MobileSideNavComponent from '../components/MobileSideNavComponent';
import DesktopSideNavComponent from '../components/DesktopSideNavComponent';
import { useDispatch, useSelector } from 'react-redux';
import React, {
	useEffect,
	useLayoutEffect,
	useState,
	createRef,
	useMemo,
	useRef,
} from 'react';
import {
	setAxiosInstance,
	setCounter,
	setDevice,
	setInitialLeagues,
	setScreenshotRef,
	setSelectedLeagueTab,
	setShowDisclaimerModal,
	updateHeader,
} from 'shared/store/global/globalActions';
import PagesEnum from 'shared/Enum/pages';
import {
	useAuth,
	useBanners,
	useLeagues,
	useMutateLogin,
	useProfile,
} from 'shared/hooks';
import { languageConfig } from '../assets/language/language';
import { axiosInstance as axios } from './axios';
import { desktopBg, mobileBg } from '../assets/images/CountDown';
import FooterNavItemsComponent from '../components/FooterNavItemsComponent/FooterNavItemsComponent';
import { useWindowResize } from 'shared/hooks/useWindowResize';
import { setLanguage } from 'shared/store/lang/langActions';
import TermsConditionsComponent from '../components/TermsConditionsComponent/TermsConditionsComponent';
import HowToPlayComponent from '../components/HowToPlayComponent/HowToPlayComponent';
import { Warnning } from 'shared/assets/images/ErrorPage';
import retryIcon from 'shared/assets/images/ErrorPage/retry.svg';
import { Container } from 'react-bootstrap';
import { appFontFamily } from '../assets/language/language';
import { useTheme } from 'styled-components';
import mainBackground from '../assets/images/general/versus_app_background.png';
import { useQueryClient } from 'react-query';

export const App = () => {
	// hooks
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { header, initialLeagues, axiosInstance, showDisclaimerModal } =
		useSelector(({ global }) => global);
	const theme = useTheme();
	const { pathname } = useLocation();
	const { isMobile } = useWindowResize();
	const screenRef = createRef();
	const headerRef = useRef(null);
	const jwt = localStorage.getItem('jwt-token');
	const routesElement = useRoutes([
		{
			path: '/',
			// element: (
			// 	<Navigate
			// 		to={user ? `/${PagesEnum.predictions}` : `/${PagesEnum.signUp}`}
			// 		replace
			// 	/>
			// ),
			element: <Navigate to={`/${PagesEnum.predictions}`} />,
		},
		{
			path: `/${PagesEnum.predictions}`,
			element: (
				<CampaignLeague
					axios={axiosInstance}
					disableOpacity
					hidePredictionCounterOnMobile={isMobile}
					counterFontFamily={appFontFamily}
					submitBgColor={'#EA336A'}
					submitTextColor={'#fff'}
					disableSubmitBtnBorder
				/>
			),
			children: [
				{
					path: ':league',
					element: (
						<CampaignLeague
							disableOpacity
							axios={axiosInstance}
							hidePredictionCounterOnMobile={isMobile}
							counterFontFamily={appFontFamily}
							submitBgColor={'#EA336A'}
							submitTextColor={'#fff'}
							disableSubmitBtnBorder
						/>
					),
				},
			],
		},
		{
			path: `/${PagesEnum.results}`,
			element: <Results counterFontFamily={appFontFamily} />,
			children: [
				{
					path: ':league',
					element: <Results counterFontFamily={appFontFamily} />,
				},
			],
		},
		{
			path: `/${PagesEnum.results}/:league/:roundId`,
			element: <ResultDetails howToPlayColor={theme.howToPlayIcon} />,
		},
		{
			path: `/${PagesEnum.leaderboard}`,
			element: <Leaderboard />,
			children: [
				{
					path: `:league`,
					element: <Leaderboard />,
				},
			],
		},
		{
			path: `/${PagesEnum.parlays}`,
			element: <Parlays />,
			children: [
				{
					path: ':league',
					element: <Parlays />,
					children: [
						{
							path: ':market',
							element: <Parlays />,
						},
					],
				},
			],
		},
		// {
		// 	path: `/${PagesEnum.signUp}`,
		// 	element: <SignUpScreen clientLogo={VersusLogo} isMobile={isMobile} />,
		// },
		// {
		// 	path: `/${PagesEnum.login}`,
		// 	element: <LoginScreen clientLogo={VersusLogo} isMobile={isMobile} />,
		// },
		{
			path: `/${PagesEnum.bracket}`,
			element: jwt ? (
				<WorldCupBracket
					iframeURL={`${process.env.NX_bracketIframeUrl}?token=${jwt}`}
				/>
			) : (
				<WorldCupBracket iframeURL={`${process.env.NX_bracketIframeUrl}`} />
			),
		},
		{
			path: `/${PagesEnum.bracket}/leaderboard`,
			element: jwt ? (
				<WorldCupBracket
					iframeURL={`${process.env.NX_bracketIframeUrl}leaderboard/?token=${jwt}`}
				/>
			) : (
				<WorldCupBracket
					iframeURL={`${process.env.NX_bracketIframeUrl}leaderboard`}
				/>
			),
		},
		{
			path: `/${PagesEnum.errorPage}`,
			element: <ErrorPage clientLogo={VersusLogo} isMobile={isMobile} />,
		},
		{
			path: `/${PagesEnum.howToPlay}`,
			element: (
				<HowToPlayWrapper
					HowToPlayTitle={'¿Cómo se juega?'}
					ItemsComponent={HowToPlayComponent}
				/>
			),

			children: [
				{
					path: `/${PagesEnum.howToPlay}/*`,
					element: <Navigate to={`/${PagesEnum.howToPlay}`} replace />,
				},
			],
		},
		{
			path: `/${PagesEnum.termsAndCond}`,
			element: (
				<TnCWrapper
					TncTitle={'Términos y Condiciones'}
					ItemsComponent={TermsConditionsComponent}
				/>
			),
		},
	]);

	// states
	const [LayoutContainer, setLayoutContainer] = useState(S.GridContainer);
	const [headerHeight, setHeaderHeight] = useState(null);

	// constants
	const username = sessionStorage.getItem('username');
	const viewPortHeight = window.innerHeight;

	// queries hooks

	const { isLoading: authLoading, data: authData } = useAuth(
		axiosInstance,
		process.env.NX_serverKey,
		username,
		process.env.NX_originServer
	);

	// useEffect(() => {
	// 	console.log({ authData, user });
	// }, [authData, user]);

	// const { refetch: refetchProfile } = useProfile(axiosInstance);
	const { mutateAsync: mutateLogin, isLoading, isSuccess } = useMutateLogin();

	const {
		data: leagues,
		isStale,
		isLoading: leaguesLoading,
		error,
		refetch,
	} = useLeagues(axiosInstance, process.env.NX_CLIENT_ID);

	// useBanners(axiosInstance, {
	// 	CAMPAIGN_ID: process.env.NX_CAMPAIGN_ID,
	// });

	const leaguesTabs = useMemo(
		() =>
			leagues &&
			Object.keys(leagues).map((league, i) => ({
				title: league,
				to: league.replace(' ', '-'),
			})),
		[leagues]
	);

	//functions

	// life cycle
	// useEffect(() => {
	// 	const jwt = localStorage.getItem('jwt-token');
	// 	if (axiosInstance && jwt && !user) {
	// 		refetchProfile();
	// 	}
	// }, [axiosInstance, refetchProfile, user]);
	// const jwt = localStorage.getItem('jwt-token');

	// useEffect(() => {
	// 	if (axiosInstance && jwt && !user) {
	// 		sessionStorage.removeItem('username');
	// 	}
	// }, [axiosInstance, jwt, user]);

	useLayoutEffect(() => {
		dispatch(setCounter(desktopBg, mobileBg));
	}, [dispatch]);

	useLayoutEffect(() => {
		if (!initialLeagues?.length) {
			dispatch(
				updateHeader({
					clientLogo: VersusLogo,
					leagues: initialLeagues,
				})
			);
		}
	}, [dispatch, initialLeagues]);

	useLayoutEffect(() => {
		dispatch(
			updateHeader({
				height: headerHeight,
			})
		);
	}, [dispatch, headerHeight]);

	useLayoutEffect(() => {
		leaguesTabs &&
			dispatch(
				setInitialLeagues([
					...leaguesTabs,
					{ title: 'Torneo', to: 'bracket', replace: true },
				])
			);
	}, [dispatch, leaguesTabs]);

	useEffect(() => {
		initialLeagues?.forEach(({ to }, i) => {
			if (to === pathname.split('/')[1] || to === pathname.split('/')[2]) {
				dispatch(setSelectedLeagueTab(i));
			}
		});
	}, [dispatch, initialLeagues, pathname]);

	useEffect(() => {
		setLayoutContainer(isMobile ? S.FlexContainer : S.GridContainer);
		dispatch(setScreenshotRef(screenRef));
	}, [dispatch, isMobile, screenRef]);

	useEffect(() => {
		setHeaderHeight(headerRef?.current?.clientHeight);
	}, [headerRef]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	// init
	// TODO: check device value when the sessionStorage logic is ready
	useLayoutEffect(() => {
		dispatch(setLanguage(languageConfig));
		dispatch(setAxiosInstance(axios));
		dispatch(setDevice(sessionStorage.getItem('os')));
	}, [dispatch]);

	useEffect(() => {
		if (showDisclaimerModal === null) {
			dispatch(setShowDisclaimerModal(true));
		} else {
			dispatch(setShowDisclaimerModal(false));
		}
	}, []); // force only on init

	// jsx
	if (leaguesLoading || !axiosInstance) {
		return (
			<Container
				fluid
				style={{
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<AppSpinner />
			</Container>
		);
	}

	if (error || !leagues) {
		return (
			<ErrorPage
				ErrorSvg={Warnning}
				title={'Page not found'}
				description={
					'The page you are looking for doesn’t exist or an other error occurred.'
				}
				btnTitle={'Try Again'}
				btnIcon={retryIcon}
				clickHandler={refetch}
			/>
		);
	}

	return (
		<LayoutContainer
			id="layout"
			appFontFamily={appFontFamily}
			ref={screenRef}
			screenHeight={viewPortHeight}
		>
			{initialLeagues?.length && (
				<S.Header id="header" $isMobile={isMobile} ref={headerRef}>
					<Header howToPlayColor={theme.howToPlayIcon} {...header} />
				</S.Header>
			)}
			<ResponsiveWrapper
				isMobile={isMobile}
				style={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					flex: '1',
					// overflowY: 'scroll',
					// margin: '1rem 0',
				}}
			>
				<S.SideNav $isMobile={isMobile}>
					<SideNav
						// clientLogo={VersusLogo}
						MobileSideNavComponent={MobileSideNavComponent}
						DesktopSideNavComponent={DesktopSideNavComponent}
					/>
				</S.SideNav>
				<S.Main
					style={{
						backgroundImage: `url(${mainBackground})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.55)',
					}}
					isMobile={isMobile}
					id="main"
					isHeader={!header.hideHeader}
					isBracket={pathname === `/${PagesEnum.bracket}`}
				>
					{/* <React.Suspense
						fallback={
							<Container
								style={{
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								fluid
							>
								<AppSpinner />
							</Container>
						}
					> */}
					{routesElement}
					{/* </React.Suspense> */}
				</S.Main>
			</ResponsiveWrapper>
			{/*
			{isMobile &&
				pathname !== `/${PagesEnum.login}` &&
				pathname !== `/${PagesEnum.signUp}` && (
					<S.Footer>
						<FooterNav
							countDownDesktopBg={desktopBg}
							countDownMobileBg={mobileBg}
							ItemsComponent={FooterNavItemsComponent}
						/>
					</S.Footer>
				)} */}
			{isMobile && (
				<S.Footer>
					<FooterNav
						countDownDesktopBg={desktopBg}
						countDownMobileBg={mobileBg}
						ItemsComponent={FooterNavItemsComponent}
					/>
				</S.Footer>
			)}
			<DisclaimerModal
				isOpen={showDisclaimerModal}
				setIsOpen={(bool) => dispatch(setShowDisclaimerModal(bool))}
			/>
		</LayoutContainer>
	);
};

const ResponsiveWrapper = ({ style, children, isMobile }) => {
	if (isMobile) {
		return (
			<div id="responsiveWrapper" style={style}>
				{children}
			</div>
		);
	}
	return children;
};

export default App;
