import styled from 'styled-components';

export const GridContainer = styled.div`
	height: ${({ screenHeight }) => `${screenHeight}px`};
	display: grid;
	grid-template-columns: 6.875rem 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		'sidenav header'
		'sidenav main';
	background-color: ${({ theme }) => theme.appBg};
	font-family: ${({ appFontFamily }) => (appFontFamily ? appFontFamily : '')};
`;

export const FlexContainer = styled.div`
	font-family: ${({ appFontFamily }) => (appFontFamily ? appFontFamily : '')};
	height: ${({ screenHeight }) => `${screenHeight}px`};
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.appBg};
`;

export const Header = styled.div`
	z-index: 1300;
	${(props) =>
		!props.$isMobile
			? {
					'grid-area': 'header',
			  }
			: {
					position: 'sticky',
					top: 0,
					width: '100%',
			  }};
`;

export const SideNav = styled.div`
	${(props) =>
		!props.$isMobile && {
			'grid-area': 'sidenav',
		}}
`;

export const Main = styled.div`
	display: flex;
	flex: 1;
	background-color: ${({ theme }) => theme.appBg};
	scroll-behavior: smooth;
	/* overflow-y: hidden; */
	padding: ${({ isHeader, isBracket }) => {
		if (isBracket) {
			return 'unset';
		}
		return isHeader ? '1rem 0' : 'unset';
	}};
`;

export const Footer = styled.div`
	z-index: 1010;
	position: sticky;
	bottom: 0;
`;
