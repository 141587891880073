import * as S from './StepperRegisteation.styles';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Button from '../../../Button';
import Typography from '@mui/material/Typography';
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';

const StepperRegisteation = ({ activeStep, handleNext, handleBack }) => {
	const steps = ['Account Details', 'Contact Dedails', 'Account Created'];

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return <FirstStep />;
			case 1:
				return <SecondStep />;
			case 2:
				return <div>3</div>;
			default:
				throw new Error('Unknown step');
		}
	};

	return (
		<S.RootContainer>
			<S.RegistrationStepper alternativeLabel id="stepp" activeStep={activeStep}>
				{steps.map((label) => (
					<Step id="step" key={label}>
						<S.RegStepLabel>
							<S.StepperLabelText>{label}</S.StepperLabelText>
							</S.RegStepLabel>
					</Step>
				))}
			</S.RegistrationStepper>
			{activeStep === steps.length - 1 ? (
				<>
					{getStepContent(activeStep)}
					<Typography variant="subtitle1">Success</Typography>
					<Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
						Back
					</Button>
				</>
			) : (
				<>
					{getStepContent(activeStep)}
					<Box
						sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}
					>
						<Button
							variant="contained"
							type="submit"
							onClick={handleNext}
							width={'100%'}
							sx={{ mt: 3, ml: 1, marginRight: '600px' }}
						>
							{activeStep > 0 ? 'CREATE NEW ACCOUNT' : 'NEXT'}
						</Button>
					</Box>
				</>
			)}
		</S.RootContainer>
	);
};

export default StepperRegisteation;
