import { TextField } from '@mui/material';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../Button';

export const ParlaysContainer = styled(Container)`
	${({ $isMobile }) => $isMobile && { fontSize: '.75em' }}
	display: flex;
	flex-direction: column;
`;

export const ParlaysBody = styled.div`
	background-color: ${({ theme }) => theme.appSectionsBg};
`;

export const FullWidthHr = styled.span`
	padding-top: 0.6rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: center;
	color: ${({ theme }) => theme.appBg};
	&:before {
		content: '';
		flex: 1 1;
		border-bottom: ${({ theme }) =>
			`${theme.leaderboardTable.titlesHr} solid 1px`};
		margin: auto;
		margin-right: unset;
	}
	&:after {
		content: '';
		flex: 1 1;
		border-bottom: ${({ theme }) =>
			`${theme.leaderboardTable.titlesHr} solid 1px`};
		margin: auto;
		margin-left: unset;
	}
`;

export const AmountsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	padding-left: 2rem;
	margin-top: 2rem;
	& input[type='number'] {
		-moz-appearance: textfield;
	}
	& input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	& input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

export const BetAmountText = styled.div`
	color: ${({ theme }) => theme.secondaryTypography};
`;

export const WinAmountText = styled.div`
	font-weight: 700;
	color: ${({ theme }) => theme.secondary};
`;

export const SubmitButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
`;

export const SubmitButton = styled(Button)`
	height: ${({ $isMobile }) => ($isMobile ? '2.4rem' : '3rem')};
	/* padding: ${({ $isMobile }) =>
		$isMobile ? '0.2rem 2rem' : '0.7rem 4rem'}; */
	padding: 0.7rem 6rem;
	max-height: 100%;
	margin: 1rem 0;
`;

export const PotentialGainText = styled.div`
	font-family: 'Prompt';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.accumulator.potentialGain};
	/* color: #ffffff80; */
	/* color: rgba(255, 255, 255, 0.5); */
	border-radius: 10px 10px 0px 0px;
`;

export const AccumulatorText = styled.div`
	font-family: 'Prompt';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.accumulator.accumulator};
`;

export const ReturnsText = styled.span`
	font-family: 'Prompt';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.accumulator.returns};
`;

export const TextInput = styled(TextField)`

	// label placeholder
	/* .MuiFormLabel-root.MuiInputLabel-root {
		color: #000000;
		opacity: 0.6;
		border-radius: 1rem;
	}
	// focused Label
	.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
		color: #000000;
	}

	& .MuiOutlinedInput-root {
		&.Mui-focused fieldset {
			border-radius: 1rem;
			border-color: #000000;
		}
	}
	// unfocused Colors
	fieldset {
		border-radius: 1rem;
		border-color: #000000;
	}
	// typing text color
	.MuiInputBase-input.MuiOutlinedInput-input {
		color: #000000;
	}

	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1rem;
	}

	// fieldset focused
	.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
		.MuiOutlinedInput-notchedOutline {
		border-color: #000000;
	} */
`;
